import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http : HttpClient
  ) { }

  getAll(location, searchParams){
	return this.http.get(`${environment.JlUrl}/location/`+ location + `/document`,{params:searchParams});
  }

  get(location, document){
	return this.http.get(`${environment.JlUrl}/location/`+location +`/document/` + document);
  }

  edit(location, document, formData){
	return this.http.put(`${environment.JlUrl}/location/`+location +`/document/` + document, formData);
  }

  create(location, formData){
	return this.http.post(`${environment.JlUrl}/location/` + location +`/document`, formData);
  }

  delete(location, document){
	return this.http.delete(`${environment.JlUrl}/location/` + location +`/document/` + document);
  }

  getAllInvoice(invoice, searchParams){
	return this.http.get(`${environment.JlUrl}/invoice/`+ invoice + `/document`,{params:searchParams});
  }

  getInvoice(invoice, document){
	return this.http.get(`${environment.JlUrl}/invoice/`+invoice +`/document/` + document);
  }

  editInvoice(invoice, document, formData){
	return this.http.put(`${environment.JlUrl}/invoice/`+invoice +`/document/` + document, formData);
  }

  createInvoice(invoice, formData){
	return this.http.post(`${environment.JlUrl}/invoice/` + invoice +`/document`, formData);
  }

  deleteInvoice(invoice, document){
	return this.http.delete(`${environment.JlUrl}/invoice/` + invoice +`/document/` + document);
  }

}