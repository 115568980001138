import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(
    private http : HttpClient
  ) { }

  getAll(searchParams){
	return this.http.get(`${environment.JlUrl}/employee/attendance`,{params:searchParams});
  }

  get(id){
	return this.http.get(`${environment.JlUrl}/employee/attendance/`+id);
  }

  edit(id,formData){
	return this.http.put(`${environment.JlUrl}/employee/attendance/`+id,formData);
  }

  create(formData){
	return this.http.post(`${environment.JlUrl}/employee/attendance`,formData);
  }

  delete(id){
	return this.http.delete(`${environment.JlUrl}/employee/attendance/`+id);
  }

  punchin(formData){
	return this.http.post(`${environment.JlUrl}/punchin`,formData);
  }

  punchout(formData){
	return this.http.post(`${environment.JlUrl}/punchout`,formData);
  }

}