import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

export class JobsheetModel {

	id: string;
    items : any[];
    customer : any[];
	cleaners: any[];
    location : any[];
    status : any[]; 
	cancel_notes : string;
    sub_total : string;
    vat_total: string;
	total_price: string;
	scheduled_start_at : string;
    scheduled_finish_at : string; 
    actual_start_at : string;
    actual_finish_at: string;
	is_manual: string;
	signed_at : string; 
    deleted_at : string;
    created_at: string;
	updated_at: string;
	signature_file_path: string

    constructor(input?){
        input = input || {};
		this.id = input.id || '';
        this.items = input.items || '';
        this.customer = input.customer || '';
		this.cleaners = input.cleaners || [];
        this.location = input.location || [];
        this.status = input.status || [];
        this.sub_total = input.sub_total || '';
		this.vat_total = input.vat_total || '';
		this.total_price = input.total_price || '';
		this.scheduled_start_at = input.scheduled_start_at || '';
        this.scheduled_finish_at = input.scheduled_finish_at || '';
        this.actual_start_at = input.actual_start_at || '';
        this.actual_finish_at = input.actual_finish_at || '';
        this.is_manual = input.is_manual || '';
        this.signed_at = input.signed_at || '';
		this.deleted_at = input.deleted_at || '';
		this.created_at = input.created_at || '';
		this.updated_at = input.updated_at || '';
		this.cancel_notes = input.cancel_notes || '';
		this.signature_file_path = input.signature_file_path || '';
    }

	static getItemsFormGroup(items?) {
		let fb: FormBuilder = new FormBuilder();

		let scFormArray = [];
		if (items.service_capability) {
			for (let isc of items.service_capability) {
				scFormArray.push(isc.id);
				
			}
		}

		let zonesFormArray = [];
		if (items.zone) {
			for (let zone of items.zone) {
				zonesFormArray.push(zone.id);
				
			}
		}
		return fb.group({
			zone: [items.zone ? items.zone.id: null],
			notes: [items.zone ? items.zone.notes: null],
			quantity: new FormControl(items ? items.quantity : ''),
			price: new FormControl(items ? items.price : ''),
			line_total: new FormControl(items ? items.line_total : ''),
			service_capability: [items.service_capability ? items.service_capability.id: null],
			reason: new FormControl(items ? items.reason : ''),
			completed_at: new FormControl(items ? items.completed_at : ''),
			completed_by: new FormControl(items.completed_by ? items.completed_by.id: null),
			image_url: new FormControl(items ? items.image_url : ''),
		});
	}

	static getCleanersFormGroup(cleaners?) {
		let fb: FormBuilder = new FormBuilder();

		return fb.group({
			id: new FormControl(cleaners ? cleaners.id : ''),
		});
	}

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();

		let ItemsFormArray = new FormArray([]);
		if (input.items) {
			for (let items of input.items) {
				ItemsFormArray.push(this.getItemsFormGroup(items));
			}
		}

		let cleanersFormArray = [];
		if (input.cleaners) {
			for (let cleaner of input.cleaners) {
				cleanersFormArray.push(cleaner.user.id);
			}
			console.log([cleanersFormArray])
		}

		return fb.group({
			id: [input.id],
			items: ItemsFormArray,
			actual_start_at: [input.actual_start_at],
			actual_finish_at: [input.actual_finish_at],
			is_manual: [input.is_manual],
			signed_at: [input.signed_at],
			deleted_at: [input.deleted_at],
			created_at: [input.created_at],
			updated_at: [input.updated_at],
			scheduled_start_at: [input.scheduled_start_at, Validators.required],
			scheduled_finish_at: [input.scheduled_finish_at, Validators.required],
			cleaners: [cleanersFormArray],
			customer: [input.customer ? input.customer.id: null],
			location: [input.location ? input.location.id: null],
			status: [input.status ? input.status.id: null, Validators.required],
			sub_total: [input.sub_total],
			vat_total: [input.vat_total],
			total_price: [input.total_price],
			cancel_notes: [input.cancel_notes],
			signature_file_path: [input.signature_file_path]
		})
	}

}