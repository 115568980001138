import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsheetService {

  constructor(
    private http : HttpClient
  ) { }

  getAll(searchParams){
	return this.http.get(`${environment.JlUrl}/jobsheet`,{params:searchParams});
  }

  getMine(searchParams){
	return this.http.get(`${environment.JlUrl}/myjobsheet`,{params:searchParams});
  }

  get(id){
	return this.http.get(`${environment.JlUrl}/jobsheet/`+id);
  }

  getMobile(id, searchParams){
	return this.http.get(`${environment.JlUrl}/jobsheet/`+id,{params:searchParams});
  }

  edit(id,formData){
	return this.http.put(`${environment.JlUrl}/jobsheet/`+id,formData);
  }

  create(formData){
	return this.http.post(`${environment.JlUrl}/jobsheet`,formData);
  }

  delete(id){
	return this.http.delete(`${environment.JlUrl}/jobsheet/`+id);
  }

  cancel(id, formData){
	return this.http.post(`${environment.JlUrl}/jobsheet/`+id + `/cancel`,formData);
  }

  start(jobsheet, formData){
	return this.http.post(`${environment.JlUrl}/jobsheet/`+jobsheet + `/start`,formData);
  }

  finish(jobsheet, formData){
	return this.http.post(`${environment.JlUrl}/jobsheet/`+jobsheet + `/finish`,formData);
  }

  status(searchParams){
	return this.http.get(`${environment.JlUrl}/jobsheet/status`,{params:searchParams});
  }

  updatecomplete(id, item, formData){
	return this.http.post(`${environment.JlUrl}/jobsheet/`+id + `/item/`+ item + `/complete`,formData);
  }

  updatenotcomplete(id, item, formData){
	return this.http.post(`${environment.JlUrl}/jobsheet/`+id + `/item/`+ item + `/notcomplete`,formData);
  }

  clash(searchParams){
	return this.http.get(`${environment.JlUrl}/jobsheet?status=conflict`,{params:searchParams});
  }

  finishStatus(searchParams){
	return this.http.get(`${environment.JlUrl}/jobsheet?status=finished`,{params:searchParams});
  }

  me(){
	return this.http.get(`${environment.JlUrl}/me`);
  }

  getDate(datefrom, dateto){
	return this.http.get(`${environment.JlUrl}/jobsheet?date_from=` + datefrom+ `&date_to=`+dateto);
  }

  late(id ,formData){
	return this.http.post(`${environment.JlUrl}/jobsheet/`+id + `/late`,formData);
  }
}